@import "_fontfaces";

body{
	font-family: 'montserratregular';
}

a{
	color: inherit;
}

hgroup{
	font-style: italic;

	h1{
		color: $golden;
		font-size: 16px;
		margin-bottom: 15px;

		&:before{
			content:'';
			width: 17px;
			height: 10px;
			background: currentColor;
			margin-right: 17px;
			display: inline-block;
		}
	}

	h2{
		font-size: 32px;
		font-family: 'montserratextrabold';
	}
}

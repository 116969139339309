.redes__sociais__topo{
	display: flex;
	justify-content: center;
	font-size: 16px;

	a{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: toEm(40, 16);
		height: toEm(40, 16);
		border: 1px solid #09205a;
		color: #09205a;
		transition: all 0.3s linear;
		margin: 0 2px;

		&:hover{
			text-decoration: none;
			background: #09205a;
			color: #FFF;
		}
	}
}


@include media-breakpoint-down(md) {
	.redes__sociais__topo{
		padding: 15px;
		border-top: 1px solid rgba(#000, .1)
	}
}


@include media-breakpoint-up(lg) {
	.redes__sociais__topo{
		grid-area: SOCIAL;
		padding-left: 30px;
		margin-left: 30px;
		border-left: 1px solid #09205a;
		align-self: center;
	}
}

lazy-image{
	display: inline-block;
	width: 100%;
	max-width: calc(var(--width) * 1px);
	max-height: calc(var(--height) * 1px);
	transition: background 0.6s linear;
	position: relative;
	vertical-align: middle;

	&.inline{
		display: inline-block;
	}

	&:before{
		content:'';
		display: block;
		padding-bottom: calc( (var(--height) / var(--width)) * 100%);
	}

	img{
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		transition: opacity 0.6s;
		left:0;
		top:0;
	}

	&:not(.loaded) {
		background: #eee;

		&:after{
			content:'';
			width: 30px;
			height: 30px;
			position: absolute;
			left: calc(50% - 15px);
			top: calc(50% - 15px);
			border: 3px solid $primary;
			border-left-color: transparent;
			border-radius: 50%;
			animation: loadingLazyElements 1s linear infinite;
			transition: opacity 0.6s;
		}
	}


	&.loaded{
		&:after{
			opacity: 0;
		}

		img{
			opacity: 1;
		}
	}
}

@keyframes loadingLazyElements{
	0%{
		transform: rotate(0deg);
	}

	100%{
		transform: rotate(359deg);
	}
}

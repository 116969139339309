.page__empresa{

	.title{
		color: $darkblue;
		font-weight: 900;
	}

	.depoimento{
		padding: 50px 0;
		background: #ededed;
		text-align: center;

		.avatar{
			width: 113px;
			height: 113px;
			border-radius: 50%;
			overflow: hidden;
			box-shadow: 0 0 5px rgba(#000,.4);
			margin: 10px auto
		}

		.autor{
			.nome{
				font-weight: 900;
				color: #222;
			}
		}
	}
}


.missao-visao-valores{
	text-align: center;

	.titulo{
		margin: 10px 0;
		font-weight: 900;
		font-size: 20px;
		color: #222;

		span{
			color: $darkblue;
		}
	}
}

#carousel-empresa{
	margin-bottom: 10px;
}

.carousel-customNav{
	&:not(.owl-loaded) {
		display: flex;
		width: 100%;
		overflow: auto;

		@include media-breakpoint-up(sm) {
			lazy-image{
				width: calc((100% - 30px) / 4);
				flex-shrink: 0;

				& + lazy-image{
					margin-left: 10px;
				}
			}
		}
	}

	.owl-nav{
		padding: 15px;
		display: flex;
		justify-content: center;

		.owl-prev,
		.owl-next{
			@extend .btn, .btn-azul;
			margin: 0 5px;
		}
	}

	lazy-image{
		border:2px solid transparent;

		&.active{
			border-color: $darkblue;
		}
	}
}

#quem-somos {
	.owl-prev,
	.owl-next{
		padding: 0.375rem 0.75rem !important
	}
}

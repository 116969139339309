#menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	a{
		color: inherit;

		&:hover{
			text-decoration: none;
		}
	}
}


@include media-breakpoint-down(md) {
	#menu {
		border-top: 1px solid rgba(#000,.1);
		border-bottom: 1px solid rgba(#000,.1);

		a{
			display: block;
			padding: 10px 15px;

			&:hover{
				background: #eee;
			}
		}

		.active a{
			&,
			&:hover{
				background: $golden;
				color: color-contrast($golden);
			}
		}
	}
}


@include media-breakpoint-up(lg) {
	#menu{
		grid-area: MENU;
		margin-top: -2px;

		&,
		li,
		a{
			display: flex;
		}

		a{
			padding: 10px;
			align-items: center;
			white-space: nowrap;
			border: 2px solid transparent;
			transition: all 0.3s linear;
		}

		.active a,
		a:hover{
			color: $golden;
			border-color: $golden;
		}

		li + li{
			margin-left: -2px;
		}
	}
}

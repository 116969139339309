.page__projetos{
	.projetos__list{
		width: 100%;
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-wrap: wrap;
	}

	.projeto__thumb{
		width: 100%;
		position: relative;
		overflow: hidden;

		img{
			transform: scale(var(--s));
			filter: blur(var(--f, 0));
			transition: transform 0.3s linear, opacity 0.3s linear, filter 0.3s linear;
		}

		.projeto__thumb__nome{
			position:absolute;
			left: 0;
			bottom: 0;
			background: rgba(#000,.8);
			color: #FFF;
			padding: 10px 15px;
			width: 100%;
			min-height: calc(20px + 1.5em);
			padding-right: 50px;
			transform: translateY(var(--t, 0));
			transition: transform 0.3s linear;

			&:before{
				content: fa-content($fa-var-search);
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				position: absolute;
				width: 40px;
				height: 100%;
				right: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #414141;
			}
		}

		@include media-breakpoint-up(lg) {
			max-width: 25%;
			--t: 100%;

			&:hover{
				--t: 0;
				--s: 1.2;
				--o: 0.6;
				--f: 4px;
			}
		}

		@include media-breakpoint-between(sm, md) {
			max-width: 50%;
		}
	}
}

.projeto__detalhes__container{
	margin-bottom: 80px;

	.projeto__detalhes__fotos{
		background: #FFF;
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column-reverse;

		.projeto__detalhes__fotos{
			margin-top: 40px;
			width: 100%;
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		@include clearfix;

		.projeto__detalhes__fotos{
			width: 50%;
			float: right;
			margin-left: 15px;
			padding-left: 15px;
			margin-bottom: 30px;
		}
	}
}

#album{
	margin-bottom: 10px;
}

#album-thumbs{
	.owl-nav{
		text-align: center;
		margin-top: 15px;
	}

	.carousel__thumb{
		border:3px solid transparent;

		&.active{
			border-color: $golden;
		}
	}

	.owl-prev,
	.owl-next{
		padding: 0.375rem 0.75rem !important;
		@extend .btn, .btn-golden;
		margin: 0 5px;
	}
}


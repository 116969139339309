
@include media-breakpoint-down(md) {
	.topo__main{
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1050;
		width: 250px;
		height: 100%;
		background: #FFF;
		box-shadow: 0 0 8px rgba(#000,.4);
		overflow: auto;
		transform: translateX(var(--x, -270px));
		transition: transform 0.6s linear;

		&.topo__main--shown{
			--x: 0;
		}

		.brand{
			padding: 30px 15px;
			text-align: center;
		}

		.topo__boas__vindas{
			padding: 10px 15px;
			text-align: center;
		}
	}
}

@include media-breakpoint-up(lg) {
	.topo{
		overflow: hidden;
	}

	.topo__main{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		margin-left: auto;
		margin-right: auto;
		max-width: map-get($container-max-widths, xl);
		display: grid;
		grid-template-columns: 1fr min-content min-content min-content;
		grid-template-rows: auto auto;
		grid-template-areas:
			'MSG ND TEL MAIL'
			'LOGO MENU MENU SOCIAL'
		;
		position: relative;
		perspective: 3px;


		.topo__boas__vindas{
			grid-area: MSG;
			padding: 10px 0;
			position: relative;

			&:before{
				content:'';
				width: 300vw;
				height: 100%;
				background: $golden;
				z-index: -1;
				left: 0;
				top: 0;
				position: absolute;
				left: -100vw;
			}
		}

		.brand{
			grid-area: LOGO;
			padding: 10px 0;
		}

		.topo__boas__vindas,
		.topo__contato{
			color: #FFF;
			align-self: center;
			font-size: 12px;
		}
	}
}

.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.page__item {
		display: inline-block;
		padding: 5px 10px;
		border: 1px solid #CCC;
		color: inherit;
		min-width: 40px;

		& ~ *{
			margin-left: -1px;
		}
	}

	a.page__item:hover{
		text-decoration: none;
		color: $darkblue;
		background: #eee;
	}

	span.page__item--active{
		background: $darkblue;
		color: color-contrast($darkblue);
	}
}

.modal__youtube{
	.modal-dialog{
		max-width: calc(100vh - 3.5rem);
		@extend .modal-dialog-centered;
	}

	.embed-responsive{
		&:before{
			padding-bottom: percentage(9/16);
			background: #000;
		}
	}
}

.backdrop{
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(#000,.8);
	z-index: 1000;
	left: 0;
	top: 0;
	animation: fadeIn 0.6s linear;

	&.topo__main--hide{
		animation: fadeOut 0.6s linear forwards;
	}
}

body.topo__main--shown{
	.mobile__controls,
	.wrapper,
	.rodape{
		animation: blurContent 0.6s linear forwards;
	}

	&.topo__main--hide{
		.mobile__controls,
		.wrapper,
		.rodape{
			animation: focusContent 0.6s linear forwards;
		}
	}
}

@keyframes blurContent{
	0% {
		filter: blur(0px)
	}
	100% {
		filter: blur(4px)
	}
}

@keyframes focusContent{
	0% {
		filter: blur(4px)
	}
	100% {
		filter: blur(0px)
	}
}

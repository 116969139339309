.page__servicos{
	color: #777;

	.subtitle-produtos{
		font-weight: bold;
		font-size: 24px;
		color: #000;

		svg{
			color: $golden;
			margin:0;
			vertical-align: bottom;
			line-height: 1;
		}
	}

	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;

		.foto{
			margin-bottom: 0;
		}

		.nome{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			padding:10px;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom:10px;
		}

		.btn{
			$bg: $darkblue;

			border-radius: 0;
			background: $bg;
			color: #FFF;

			&:hover{
				background: darken($bg, 10%);
				color:#FFF;
			}
		}
	}


	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: $darkblue;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: $yellow;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
}

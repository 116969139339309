#banner{

	.carousel-indicators{
		filter: drop-shadow(0 0 4px rgba(#000,.4));

		li{
			width: 30px;
			height: 18px;
			border: 1px solid #FFF;
			background: transparent;
			opacity: 1;
			position: relative;

			&::before{
				content:'';
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				left: 2px;
				top: 2px;
				background: #FFF;
				position: absolute;
				transform: scale(var(--s, 0));
				transition: transform 0.3s linear;
			}

			&.active{
				--s: 1;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.carousel-indicators{
			bottom: percentage(245/806);
		}
	}
}


.section_quemsomos{
	background: $darkblue;
	color: #FFF;
	padding-top: 60px;
	padding-bottom: 30px;
	overflow: hidden;
	position: relative;
	perspective: 3px;

	.btn{
		width: 100%;
		max-width: 200px;
		font-family: 'montserratregular';
		font-style: italic;
	}

	.diferenciais{
		position: relative;

		&:before{
			content:'';
			position: absolute;
			width: 100vw;
			height: 100%;
			background: #FFF;
			left: 50%;
			top: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 30px;
		}

		@include media-breakpoint-between(sm, md) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
		}

		@include media-breakpoint-down(xs) {
			.diferencial + .diferencial{
				margin-top: 30px;
			}
		}
	}

	.diferencial{
		border: 2px solid $golden;
		position: relative;
		padding: 35px;
		text-align: center;
		z-index: 3;
		overflow: hidden;
		font-family: 'montserratextrabold';
		font-size: 20px;

		&:before{
			content:'';
			background: $golden;
			width: var(--w, 100%);
			height: var(--w, 100%);
			position: absolute;
			left: var(--l, 0);
			top: var(--l, 0);
			z-index: -2;
			transition: all 0.3s linear;
		}

		&:after{
			content:'';
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			left: 4px;
			top: 4px;
			position: absolute;
			z-index: -1;
			background: darken($golden, 10%);
			mask-image: var(--mask);
			mask-position: center center;
			mask-size: contain;
			mask-repeat: no-repeat;
			opacity: var(--o, 0);
			transform: scale(var(--s, 2));
			transition: all 0.3s linear;
		}

		&:hover{
			--w: calc(100% - 8px);
			--l: 4px;
			--s: 1;
			--o: 1;
		}

		.diferencial__counter{
			font-size: 2em;
		}
	}
}

.section__servicos{
	overflow: hidden;

	hgroup{
		margin-bottom: 25px;

		h2{
			color: $darkblue;
		}
	}
}

.section__servicos__content{
	@include media-breakpoint-up(xl) {
		width: 160%;
		max-width: 1920px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		display: grid;
		grid-template-columns: repeat(2, 50%);

		.section__servicos__textarea{
			width: #{map-get($container-max-widths, xl) / 2};
			margin-left: auto;
			padding-right: 15px;
			padding-left: 15px;
		}

		lazy-image{
			max-width: 100%;
			max-height: 100%;

			img{
				object-fit: cover;
			}
		}
	}

	a:hover{
		text-decoration: none;
	}
}

.section__servicos__textarea{
	padding-right: 15px;
	padding-left: 15px;

	@include media-breakpoint-only(lg) {
		max-width: map-get($container-max-widths, lg);
		margin-right: auto;
		margin-left: auto;
	}
}

.section__servicos__image {
	&:empty {
		&:before{
			content:'';
			padding-bottom: percentage(823/960);
			background: #DDD;
			display: block;
		}
	}

	@include media-breakpoint-down(lg) {
		lazy-image{
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.servico__principal{
	display: flex;
	font-size: 14px;
	color: #707070;
	margin-bottom: 30px;
	--bg: #{$golden};

	&:hover{
		--l: 5px;
		--bg: #{$darkblue};
		--w: calc(100% - 10px);
		--icon: #{$golden};
	}
}

.servico__principal__icon{
	width: 130px;
	height: 86px;
	border:1px solid var(--bg);
	margin-right: 20px;
	flex-shrink: 0;
	position: relative;

	&:before,
	&:after{
		content:'';
		display: block;
		position: absolute;
	}

	&:before{
		background: var(--icon, #FFF);
		mask-image: var(--mask, none);
		mask-position: center center;
		mask-repeat: no-repeat;
		mask-size: contain;
		width: calc(100% - 24px);
		height: calc(100% - 24px);
		left: 12px;
		top: 12px;
		z-index: 2;
		transition: all 0.3s linear;
	}

	&:after{
		z-index: 1;
		background: var(--bg);
		height: var(--w, 100%);
		width: var(--w, 100%);
		left: var(--l, 0);
		top: var(--l, 0);
		transition: all 0.3s linear;
	}
}

.servico__principal__nome{
	font-size: 18px;
	font-family: 'montserratextrabold';
	color: $golden;
	@include line-clamp(2);
}


.servico__principal__content{
	line-height: 1.5;
	@include line-clamp(3);
}

.section__projetos{
	overflow: hidden;

	hgroup{
		margin-bottom: 100px;
	}

	.section__projetos__capa{
		padding-top: 70px;
		padding-bottom: 70px;
		position: relative;
		perspective: 3px;
		color: #FFF;
		min-height: 554px;

		&:before{
			content:'';
			position: absolute;
			width: 100vw;
			height: 100%;
			max-height: 554px;
			background-color: $darkblue;
			z-index: -1;
			left: -15px;
			top: 0;
			max-width: 1515px;
			background-image: var(--lazybg);
		}
	}
}


.owl-projeto-index{
	@include media-breakpoint-up(xl) {
		width: 75%;
		margin-left: auto;

		.owl-nav{
			top: 230px;
			left: #{map-get($container-max-widths, xl) / -4};
			width: #{map-get($container-max-widths, xl) / 4};
			position: absolute;
		}
	}

	@include media-breakpoint-down(lg) {
		.owl-nav{
			text-align: center;
			margin-top: 30px;
		}
	}
}

#owl-projeto-index{

	a:hover{
		text-decoration: none;
	}

	.owl-prev,
	.owl-next{
		--bg: #{$golden};
		width: 50px !important;
		height: 77px !important;
		border:2px solid var(--bg);
		position: relative;
		perspective: 3px;
		color: #FFF;
		transition: all 0.3s linear;
		outline: none !important;

		&:before{
			content: '';
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			left: 5px;
			top: 5px;
			background: var(--bg);
			position: absolute;
			z-index: -1;
			transition: all 0.3s linear;
		}

		&.disabled{
			--bg: #DDD;
			color: #666;
		}

		&:not(.disabled):hover{
			--bg: #{$darkblue};
		}
	}

	.owl-next{
		margin-left: 10px;
	}
}

.projeto__thumbnail{
	width: 100%;
	max-width: 256px;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		--o: 1;
	}
}

.projeto__thumbnail__body{
	border-left: 10px solid $golden;
	padding-left: 20px;
	margin-top: 15px;
}

.projeto__thumbnail__nome{
	color: $golden;
	margin-bottom: 10px;
	font-family: 'montserratextrabold';
	@include line-clamp(2);
}

.projeto__thumbnail__content{
	@include line-clamp(4);
	color: #707070;
}

.projeto__thumbnail__foto{
	position: relative;

	lazy-image{
		z-index: 1;
	}

	&:before{
		content:'';
		position: absolute;
		width: calc(100% - 20px);
		height: calc(100% - 20px);
		left: 10px;
		top: 10px;
		background: url('../images/zoom-in.png') rgba($golden, 0.8) center center no-repeat;
		z-index: 5;
		opacity: var(--o, 0);
		transition: opacity 0.3s linear;
	}
}

.section__parceiros{
	padding-bottom: 50px;

	hgroup{
		color: $darkblue;
		margin-bottom: 50px;
	}

	.owl-carousel{
		padding-left: 60px;
		padding-right: 60px;

		.owl-prev,
		.owl-next{
			--bg: #{$golden};

			width: 50px !important;
			height: 70px !important;
			border:2px solid var(--bg) !important;
			position: relative;
			perspective: 3px;
			color: #FFF !important;
			transition: all 0.3s linear;
			outline: none !important;
			position: absolute;
			top: 50%;
			transform: translateY(-45px);

			&:before{
				content: '';
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				left: 5px;
				top: 5px;
				background: var(--bg);
				position: absolute;
				z-index: -1;
				transition: all 0.3s linear;
			}

			&.disabled{
				--bg: #DDD;
				color: #666;
			}

			&:not(.disabled):hover{
				--bg: #{$darkblue};
			}
		}

		.owl-prev{
			left: 0;
		}

		.owl-next{
			right: 0;
		}
	}

}

.select-custom{
	&.select-custom-ready{
		position: relative;

		select{
			padding:0;
			border: none;
			width: 0;
			height: 0;
			position: absolute;
		}
	}

	.output{
		text-align: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&::before{
			content:'';
			display: block;
			border-left: 0.5em solid transparent;
			border-right: 0.5em solid transparent;
			border-top: 0.5em solid currentColor;
			float: right;
			margin-top: 0.5em;
			margin-left: 0.75rem;
			transform: rotate(var(--r, 0deg));
			transition:all 0.3s linear;
		}

		&[aria-expanded="true"] {--r: -180deg}
	}


	.filtro ~ .drop-items{
		--t: 10px;
	}

	.dropdown-menu{
		padding: .5rem 10px;
	}

	.drop-items{
		list-style: none;
		padding-left:0;
		margin-top: var(--t, 0px);
		margin-bottom: 0;
		margin-left: -10px;
		margin-right: -10px;
		max-height: 132px;
		overflow: auto;

		li{
			padding: 10px 15px;

			&:not(.disabled){
				cursor: pointer;

				&:not(.selected):hover{
					background: #EEE;
				}

				&.selected {
					&,
					&:hover{
						background: $primary;
						color: #FFF;
					}
				}
			}

			&.disabled{
				pointer-events: none;
				color: #6c757d;
			}

			&.hide{
				display: none;
			}
		}
	}
}

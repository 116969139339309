.page__contato{
	padding-bottom: 80px;


	#mapa-contato{
		padding-bottom: percentage(660/1920);
		min-height: 200px;
	}

	.pdt-40{
		padding-top: 40px;
	}

	h2{
		border-bottom: 1px solid currentcolor;
		margin-bottom: 30px;
		color: $darkblue;

		&:after{
			content:'';
			display: block;
			width:60px;
			height: 2px;
			background: currentcolor;
			margin-top:10px;
		}
	}

	h3{
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	h4{
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	.info-block{
		color: #777;
	}

	.has-success{
		.form-control{
			border-color: $success;
		}
	}

	.has-error{
		.form-control{
			border-color: $danger;
		}
	}

	.has-warning{
		.form-control{
			border-color: $warning;
		}
	}

	.btn-enviar{
		border-radius: 0;
		@include button-variant(#61A93E, #61A93E);
		color: #FFF;

		&:hover{
			color: #FFF;
		}
	}
}

// .topo__contato{

// }

.topo__contato__content{
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;

	a{
		color: inherit;
	}
}


@include media-breakpoint-down(md) {
	.topo__contato{
		border-top: 1px solid rgba(#000,.1);

		.topo__contato__title{
			display: none;
		}

		a:hover{
			text-decoration: none;
			background: #eee;
			color: $primary;
		}
	}

	.topo__contato__content a{
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		padding: 10px 15px;

		&:before{
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			margin-right: 10px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
		}
	}

	.topo__emails .topo__contato__content a:before{
		content: fa-content($fa-var-envelope);
	}

	.topo__telefones .topo__contato__content a:before{
		content: fa-content($fa-var-phone);
		transform: scale(-1,1);
	}
}

@include media-breakpoint-up(lg) {
	.topo__contato__content{
		display: flex;
	}

	.topo__telefones{
		grid-area: TEL;

		.topo__contato__title:before{
			content: fa-content($fa-var-phone);
			transform: scale(-1, 1);
		}
	}

	.topo__emails{
		grid-area: MAIL;

		.topo__contato__title:before{
			content: fa-content($fa-var-envelope)
		}
	}

	.topo__contato {
		display: flex;
		white-space: nowrap;
		align-items: center;

		& + .topo__contato{
			margin-left: 30px;
		}
	}

	.topo__contato__title{
		margin-right: 5px;
		display: flex;
		align-items: center;

		&:before{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #FFF;
			color: $golden;
			width: 20px;
			height: 20px;
			font-size: 12px;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			margin-right: 10px;
			vertical-align: middle;
			line-height: 1;
		}
	}
}

.btn-pill{
	border-radius: 2rem;
}

.btn-enviar{
	@include button-variant($golden, $golden);
	border-radius: 0;
}

.btn-golden{
	@include button-variant($golden, $golden);
}

.btn-azul{
	@include button-variant($darkblue, $darkblue);
}

.btn-outline-clip{
	--bg: #{$golden};
	border-width: 2px;
	border-radius: 0;
	border-color: var(--bg);
	color: var(--color, #FFF);
	position: relative;
	perspective: 3px;

	&:before{
		content:'';
		position: absolute;
		left: 0.1875rem;
		right: 0.1875rem;
		top: 0.1875rem;
		bottom: 0.1875rem;
		background: var(--bg);
		z-index: -1;
	}

	&:hover{
		--bg: #{darken($golden, 10%)};
		--color: #{color-contrast(darken($golden, 10%))};
	}
}

.btn-youtube{
	background: #d78e1b;
	border-width: 10px;
	border-color: #1f2431;
	width: 100px;
	height: 80px;
	border-radius: 15px;
	font-size: 34px;
}

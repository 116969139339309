.mobile__controls{
	display: flex;
	justify-content: space-between;
	box-shadow: 0 0 8px rgba(#000,.4);
	background: #FFF;
	z-index: 999;

	.brand{
		display: block;
		padding: 5px 10px;
		max-width: 100%;
	}

	.btn{
		border-radius: 0;
		font-size: 20px;
		flex-shrink: 0;

		&:hover{
			background: #eee;
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.rodape__wrapper{
	padding: 60px 0;

	h1{
		color: $golden;
		font-size: 20px;
		margin-bottom: 15px;
	}

	.brand{
		margin-bottom: 30px;
	}

	.redes__sociais{
		display: flex;

		a{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: toEm(40, 16);
			height: toEm(40, 16);
			border: 1px solid $golden;
			color: $golden;
			transition: all 0.3s linear;
			margin: 0 2px;

			&:hover{
				text-decoration: none;
				background: $golden;
				color: #FFF;
			}
		}
	}

	.form-control{
		border-radius: 0;
	}

	textarea.form-control{
		min-height: 145px;
	}

	.form-group{
		position: relative
	}

	.btn-enviar{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 58px;
		height: 58px;
	}

	.content__editable{
		font-family: 'montserratbold_italic';
	}
}


@include media-breakpoint-up(lg) {
	.rodape__wrapper{
		background-image: var(--lazybg, none);
		background-position: center top;
		background-repeat: no-repeat;
	}
}

@include media-breakpoint-down(md) {
	.rodape__wrapper{
		text-align: center;

		.redes__sociais{
			justify-content: center;
			margin-bottom: 40px;
		}

		.btn-enviar{
			display: flex;
			margin-left: auto;
		}
	}
}

@include media-breakpoint-only(lg) {
	.rodape__wrapper{
		.container{
			display: grid;
			grid-template-columns:repeat(2, 50%);
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'BRAND FORM'
				'CONTATO FORM'
				'SOCIAL FORM'
			;
		}

		.brand{
			grid-area: BRAND;
			padding-right: 15px;
		}

		.contatos{
			grid-area: CONTATO;
			padding-right: 15px;
		}

		.redes__sociais{
			grid-area: SOCIAL;
			padding-right: 15px;
		}

		.form__footer{
			grid-area: FORM;
			padding-left: 15px;
		}

		.btn-enviar{
			position: absolute;
			right: 0;
			bottom: 15px;
			z-index: 10;
		}
	}
}

@include media-breakpoint-up(xl) {
	.rodape__wrapper{
		.container{
			display: grid;
			grid-template-columns: 42% 58%;
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'BRAND FORM'
				'CONTATO FORM'
				'SOCIAL FORM'
			;
		}

		.brand{
			grid-area: BRAND;
			padding-right: 15px;
		}

		.contatos{
			grid-area: CONTATO;
			padding-right: 15px;
		}

		.redes__sociais{
			grid-area: SOCIAL;
			padding-right: 15px;
		}

		.form__footer{
			grid-area: FORM;
			padding-left: 15px;
		}

		.btn-enviar{
			position: absolute;
			right: 0;
			bottom: 15px;
			z-index: 10;
		}
	}
}

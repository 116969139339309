@import "mobile__controls";
@import "topo__main";
@import "menu";
@import "topo__contato";
@import "redes__sociais__topo";
@import "backdrop";


.topo{
	position: sticky;
	top: 0;
	z-index: 1000;
	background: #FFF;
	box-shadow: 0 0 8px rgba(#000,.4);;
	font-family: 'montserratbold_italic';
	color: #09205a;

	@include media-breakpoint-up(lg) {
		font-size: 13px;
	}
}
